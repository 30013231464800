<template>
  <div class="contaienr" v-if="loading">
    <div class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      required: true,
      type: Boolean
    }
  }
}
</script>

<style lang="less" scoped>
.contaienr {
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
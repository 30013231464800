<template>
  <div style="background: #F6F6F6; height: 100%; padding: 0 5px;">
    <van-nav-bar title="确认订单" left-arrow placeholder @click-left="$router.go(-1)" />
    <div @click="$router.push({ path: '/shippingAddress', query: { first: 0 } })">
      <div 
        v-if="address == ''" 
        style="
              display: flex;
              padding: 15px 15px;
              align-items: center;
              background-color: #ffffff;
              border-radius: 5px;
              flex-direction: column;">
        <div>
          <van-icon size="20px" name="add-o" />
        </div>
        <div>点击添加收货地址</div>
      </div>
      <div v-if="address != ''">
        <van-cell is-link center>
          <template #title>
            <span class="title">{{ address.userName }}</span>
            <span class="title" style="margin-left: 6px">{{
              address.telNum
            }}</span>
            <van-tag color="#FF0038" text-color="#ffffff" v-show="address.isDefault == 1"
              style="margin-left: 8px">默认</van-tag>
          </template>
          <template #label>
            <span class="label">{{
              address.provinceName +
              address.cityName +
              address.countyName +
              address.detailInfo
            }}</span>
          </template>
        </van-cell>
      </div>
    </div>

    <div class="shopInfo">
      <img :src="url" alt="">
      <div class="shopInfo-detail">
        <p>{{ name }}</p>
        <div class="shopInfo-detail-price">
          <span class="shopInfo-detail-price-currentPrice">
            <!-- 改这里（陈曹宇） -->
            <!-- <img src="./mi.png" alt=""> -->
            <span>{{ goodsSku.ricePrice }} <span style="font-size: 10px;">驿马</span><span style="font-size: 6px;"> +
              </span>{{
                goodsSku.cash
              }}<span style="font-size: 10px;">元</span></span>
          </span>
          <!-- <span class="shopInfo-detail-price-originalPrice">￥{{ goodsSku.salesPrice }}</span> -->
        </div>
        <div class="shopInfo-detail-specification">
          <span>规格：{{ goodsSku.name }}</span>
          <span>x{{ count }}</span>
        </div>
      </div>
    </div>
    <!-- 价格展示大改（陈曹宇） -->
    <div class="peice-serve">
      <p>实际支付</p>
      <div>
        <div class="peice-serve-price">
          <span>现金（元）</span>
          <span>{{ totalCash }} 元</span>
        </div>
        <div class="peice-serve-price">
          <span>驿马</span>
          <span>{{ totalRicePrice }} 驿马</span>
        </div>
        <div class="peice-serve-price">
          <span>运费（驿马）</span>
          <span>{{ postage }} 驿马</span>
        </div>

        <div @click="openTicket" class="ticket" v-if="!$route.query.ticketActive">
          <span>优惠券</span>
          <span>
            <span v-for="(x, i) in nameTicket" :key="i">{{ x }} <span v-if="nameTicket.length - 1 != i">,</span></span>
            <van-icon name="arrow" />
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="peice-serve">
      <span class="peice-serve-span">商品总价</span>
      <img class="peice-serve-img" src="./mi.png" alt="">
      <span>{{ totalPrice }}</span>
    </div>

    <div class="peice-serve" v-show="!disabled">
      <span class="peice-serve-span">邮费</span>
      <img class="peice-serve-img" src="./mi.png" alt="">
      <span> {{ postage }}</span>
    </div> -->

    <!-- <div class="zhifuType">
      <p>支付方式</p>
      <div class="zhifuType-radio">
        <div class="zhifuType-radio-item">
          <img style="width: 0.8108rem; height: 0.8108rem;" src="./mili.png" alt="">
          <span style="flex: 1; margin-left: 0.1351rem;">驿马</span>
          <span>
            <van-radio-group v-model="radio">
              <van-radio name="0" checked-color="#ee0a24"></van-radio>
            </van-radio-group>
          </span>
        </div>
      </div>
    </div> -->

    <div class="confirm">
      <!-- <span>
        <span style="color: #333;">实际支付：</span>
        <img class="confirm-img" src="./mi.png" alt="">
        <span style="color: #E54320; font-size: 25px;">{{ (totalPrice + postage).toFixed(4) }}</span>
      </span> -->
      <!-- <span> -->
      <van-button :disabled="disabled" color="#E54320" size="normal" round @click="confirm">提交订单</van-button>
      <!-- </span> -->
    </div>

    <div>
      <ZhiFu :ticketId="ticketId" :show="showPay" :price="totalCash" :data="id" @getSend="getShow"></ZhiFu>
      <PaySuccess :paySuccessBoolean="isPaySuccess" :data="id" :page="0" />
      <!-- <van-action-sheet v-model="show" title=" ">
        <div class="content">
          <div class="content-img">
            <img style="width: 135px;" src="./success.png" alt="">
            <p>兑换成功</p>
          </div>
          <div class="content-btn">
            <span class="content-btn-span">
              <van-button round type="default" @click="toHome">返回首页</van-button>
            </span>
            <span>
              <van-button round type="danger" @click="checkOrder">查看订单</van-button>
            </span>
          </div>
        </div>
      </van-action-sheet> -->
    </div>

    <zhifu-ticket :ticketBoolean="ticketBoolean" :sysCoupons="sysCoupons" :shopCoupons="shopCoupons"
      :vipCoupons="vipCoupons" :totalPrice="standby" @getName="getName" @closeTicket="closeTicket" />

    <loading :loading="loading" />
  </div>
</template>

<script>
import { getPage } from "@/api/shoppingAddress/shoppingAddress"
import { confirmOrder, orderCommit, getPostage } from '@/api/detail'
import { Toast, Dialog } from 'vant'
import Loading from './Loading'
import ZhifuTicket from './actionSheet/ZhifuTicket'
import { useTicket } from '@/api/flMall'
// 引入支付接口
import ZhiFu from '@/views/milimallDetail/ZhiFu.vue'
import PaySuccess from '@/views/milimallDetail/PaySuccess.vue'
let backAddress = ''
export default {
  components: { Loading, ZhiFu, PaySuccess, ZhifuTicket },
  data() {
    return {
      showPay: false,//支付弹出层默认不显示（陈曹宇）
      isPaySuccess: false,//订单是否支付成功
      address: '',
      oneSkuPrice: '', // 单价
      totalPrice: '', // 实际支付
      procedures: '', // 服务费
      goodsSku: {},
      show: false,
      goodsId: '',
      skuId: '',
      count: '',
      url: '',
      id: '',
      postage: 0, // 邮费
      disabled: false,
      radio: '0',
      name: '',
      loading: false,
      totalRicePrice: '',//实际支付驿马
      totalCash: '',//实际支付现金
      ticketBoolean: false,
      sysCoupons: [],
      shopCoupons: [],
      vipCoupons: [],
      nameTicket: [],
      ticketId: {
        sysCouponUserId: '',
        shopCouponUserId: ''
      },
      standby: 0
    }
  },
  watch: {
    isPaySuccess(Boolean) {
      if (!Boolean) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          // Toast('返回首页')
          window.webkit.messageHandlers.returnHomePage.postMessage('returnHomePage')
        }
        if (isAndroid) {
          window.android.returnHomePage()
        }
      }
    },
    /*  show(Boolean) {
       if (!Boolean) {
         let u = navigator.userAgent;
         let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
         let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
         if (isIOS) {
           window.webkit.messageHandlers.paySuccess.postMessage('paySuccess')
         }
         if (isAndroid) {
           window.android.paySuccess()
         }
       }
     }, */

    address: {
      handler() {
        this.getPostage()
      },
      deep: true
    }
  },

  beforeRouteEnter(to, from, next) {
    if (from.name === 'ShippingAddress') {
      if (from.params.address) backAddress = from.params.address
    } else {
      backAddress = ''
    }
    next()
  },

  created() {
    this.goodsId = this.$route.query.goodsId
    this.skuId = this.$route.query.skuId
    this.count = this.$route.query.count
    if (backAddress == '' || !backAddress) {
      if (
        this.$route.query.address != null &&
        this.$route.query.address != ''
      ) {
        if (JSON.parse(this.$route.query.address) != '') {
          this.address = JSON.parse(this.$route.query.address)
        } else {
          this.getPage()
        }
      } else {
        this.getPage()
      }
    } else {
      this.address = backAddress
    }

  },

  mounted() {
    this.confirmOrder()
    window.paySuccess = this.paySuccess
  },
  methods: {
    //接收支付子传父
    getShow(val) {
      this.showPay = val
    },
    //支付成功调用方法
    paySuccess() {
      this.isPaySuccess = true
      this.showInterstitialAD()
    },
    getPage() {
      getPage({ current: 1, size: 10 }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.records.length == 0) {
            this.address = ""
          } else {
            this.address = res.data.data.records[0]
          }
        }
      })
    },

    // 确认订单
    confirmOrder() {
      this.loading = true
      let data = {
        goodsId: this.$route.query.goodsId,
        skuId: this.$route.query.skuId,
        count: this.$route.query.count,
        payType: '0'
      }
      confirmOrder(Object.assign(data, this.ticketId)).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.useTicket(res.data.data.standby)
          this.oneSkuPrice = res.data.data.oneSkuPrice
          this.totalPrice = res.data.data.totalPrice
          this.url = res.data.data.goodsSpu.picUrls[0]//图片地址
          this.procedures = res.data.data.procedures//服务费=>后台已和驿马合并
          this.goodsSku = res.data.data.goodsSku//规格售价信息
          this.name = res.data.data.goodsSpu.name
          // 新增
          this.totalRicePrice = res.data.data.ricePrice,//实际支付驿马
            this.totalCash = res.data.data.cash//实际支付现金
          this.standby = res.data.data.standby
          // Toast(this.totalCash)
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },

    // 获取邮费
    getPostage() {
      const goodsId = this.$route.query.goodsId
      const data = {
        provinceName: this.address.provinceName,
        cityName: this.address.cityName,
        countyName: this.address.countyName,
        userName: this.address.userName,
        telNum: this.address.telNum,
        detailInfo: this.address.detailInfo
      }
      getPostage(0, goodsId, data).then(res => {
        if (res.data.code === 0) {
          this.postage = res.data.data
          this.disabled = false
        } else {
          this.disabled = true
          Toast(res.data.message)
        }
      })
    },

    // 提交订单那
    confirm() {
      if (!this.address) {
        Toast('请选择地址')
        return
      }
      const data = {
        goodsId: this.goodsId,
        skuId: this.skuId,
        count: this.count,
        procedures: this.procedures,
        postage: this.postage,
        // payType: this.totalCash == 0 ? '1' : '0',//0是现金+驿马，1是驿马
        payType: '0',//0是现金+驿马，1是驿马
        specInfo: this.goodsSku.name,
        advertisementId: this.$route.query.advertisementId||'',
        advertisementType: this.$route.query.advertisementType||'0',
        userAddress: {
          provinceName: this.address.provinceName,
          cityName: this.address.cityName,
          countyName: this.address.countyName,
          userName: this.address.userName,
          telNum: this.address.telNum,
          detailInfo: this.address.detailInfo
        }
      }
      Dialog.confirm({
        title: '订单',
        message: '确认提交订单？',
      }).then(() => {
        this.loading = true
        orderCommit(Object.assign(data, this.ticketId)).then(res => {
          if (res.data.code === 0) {
            this.loading = false
            this.id = res.data.data
            //判断现金是否为0？
            // if (this.totalCash == 0) {
            // this.paySuccess()
            // this.show = true
            // 兑换完成后，弹出兑换成功
            // } else {
            this.showPay = true//现金支付弹窗
            // }
          } else {
            this.loading = false
            Toast(res.data.message)
          }
        }).catch(() => {
          Toast('服务繁忙，请稍后再试。')
        })
      })
        .catch(() => {
        })
    },
    toHome() {
      this.isPaySuccess = false
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.returnHomePage.postMessage('paySuccess')
      }
      if (isAndroid) {
        window.android.returnHomePage()
      }
    },
    checkOrder() {
      this.$router.push({
        path: '/miliOrderInfo',
        query: {
          id: this.id,
        }
      })
    },

    //广告弹出方法
    showInterstitialAD() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.showInterstitialAD.postMessage("showInterstitialAD")
      }
      if (isAndroid) {
        window.android.showInterstitialAD()
      }
    },

    useTicket(money) {
      const data = {
        shopIds: this.$route.query.shopIds,
        spuIds: this.$route.query.id,
        money
      }
      useTicket(data).then(res => {
        if (res.data.code === 0) {
          this.sysCoupons = res.data.data.sysCoupons
          this.shopCoupons = res.data.data.shopCoupons
          this.vipCoupons = res.data.data.vipCoupons
        }
      })
    },

    getName(data) {
      this.ticketId = data.ticketId
      this.nameTicket = data.name
      this.confirmOrder()
    },

    openTicket() {
      this.ticketBoolean = true
    },

    closeTicket() {
      this.ticketBoolean = false
    },
  }
}
</script>

<style lang="less" scoped>
.shopInfo {
  background: white;
  margin-top: 10.0011px;
  padding: 0.6757rem 0.2703rem;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;

  img {
    width: 2.1622rem;
    max-height: 2.1622rem;
  }

  &-detail {
    flex: 1;
    margin-left: 0.2703rem;

    p {
      font-size: 0.3784rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      color: #333;
    }

    &-price {
      &-currentPrice {
        color: #E54320;

        img {
          width: 0.4595rem;
          height: 0.4595rem;
          transform: translateY(0.0541rem);
        }
      }

      &-currentPrice span:nth-of-type(1) {
        font-size: 14px;
        font-weight: 600;
        color: #E54320;
      }

      &-originalPrice {
        color: #999999;
        margin-left: 0.3514rem;
        text-decoration: line-through;
      }
    }

    &-specification {
      font-size: 0.3243rem;
      color: #999;
      display: flex;
      justify-content: space-between;
    }
  }
}

.peice-serve {
  background: white;
  padding: 0.2703rem;
  font-size: 0.3784rem;
  box-sizing: border-box;
  margin-top: 0.2703rem;
  border-radius: 5px;

  //新增及注释内容（陈曹宇）
  // display: flex;
  &-price {
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  /* &-span {
    flex: 1;
  } */

  /* &-img {
    width: 13px;
    height: 13px;
    transform: translateY(3.5px);
  } */
}

.zhifuType {
  background: white;
  padding: 0.2703rem;
  margin-top: 0.2703rem;
  border-radius: 5px;

  p {
    font-size: 0.3784rem;
    padding-bottom: 0.1351rem;
    border-bottom: 1px solid #E2E2E2;
    margin-bottom: 0.1351rem;
  }

  &-radio {
    &-item {
      display: flex;
      align-items: center;

      span {
        font-size: 0.3784rem;
      }
    }
  }
}

.peice-serve span:first-child {
  color: #333;
}

.peice-serve span:last-child {
  color: #E54320;
}

.confirm {
  position: fixed;
  background: #fff;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10%;
  font-size: 0.3784rem;
  padding: 0.2703rem 0.4054rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  //注释（陈曹宇）
  /* &-img {
    width: 0.5946rem;
    height: 0.5946rem;
    transform: translateY(2px);
  } */
  .van-button {
    width: 100%;
  }
}

.content {
  height: 8.9189rem;
  padding: 0.8108rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &-img {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 0.8108rem;
    }

    p {
      font-size: 0.5405rem;
      color: #333;
    }
  }

  &-btn {
    .van-button {
      width: 3.7838rem;
      height: 1.0811rem;
    }

    &-span {
      margin-right: 0.5405rem;
    }
  }
}

.ticket {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<template>
  <div>
    <van-popup v-model="_paySuccessBoolean" round position="bottom" :style="{ height: '35%' }">
      <div class="container">
        <p class="container-title">兑换成功</p>
        <div class="container-icon">
          <div>
            <van-icon color="#F52556" size="60" name="checked" />
          </div>
          <p style="font-size: 20px;">恭喜您，兑换成功！</p>
        </div>
        <div class="container-btn">
          <van-button type="default" color="#E54320" block round @click="toHome">返回首页</van-button>
          <van-button type="primary" color="#E54320" block round @click="enterOrderList">立即查看</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import { Toast } from 'vant'

export default {
  props: {
    paySuccessBoolean: {
      type: Boolean,
      required: true
    },
    data: {
      type: String
    },
    page: {
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    _paySuccessBoolean: {
      get() {
        return this.paySuccessBoolean
      },
      set() {
        this.$parent.paySuccessBoolean = false
      }
    }
  },

  methods: {
    //关闭弹出页
    closeWin() {
      this.$emit('closeWin', false)
    },
    toHome() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.returnHomePage.postMessage('paySuccess')
      }
      if (isAndroid) {
        window.android.returnHomePage()
      }
    },
    enterOrderList() {
      // Toast(this.page)
      if (this.page == '1') {
        // Toast('走了吗？1111')
        // this.paySuccessBoolean = false
        this.closeWin()
      } else {
        this.$router.replace({
          path: '/miliOrderInfo',
          query: {
            id: this.data,
          }
        })
      }
    },
  }

}
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  display: flex;
  padding: 15px 10px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-btn {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .van-button {
      width: 140px;
      height: 40px;
    }
  }
}
</style>
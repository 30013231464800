import https from "@/router/https"

// 商品详情接口
export function getDetailApi(id) {
  return https.fetchGet("/mallapp/goodsSpuFuNeng/" + id);
}

// 赋能鬼市订单
export function getInfoApi(id) {
  return https.fetchPost("/mallapp/orderinfo/fuNengOrder/" + id);
}

// 确认订单
export function confirmOrder(params) {
  return https.fetchGet('/mallapp/orderinfo/confirmOrder', params)
}

// 邮费
export function getPostage(payType, goodsId, data) {
  return https.fetchPost(`/mallapp/orderinfo/getPostage?goodsId=${goodsId}&payType=${payType}`, data)
}

// 下单
export function orderCommit(data) {
  return https.fetchPost('/mallapp/orderinfo/orderCommit', data)
}

// 订单详情
export function orderDetail(id) {
  return https.fetchGet('/mallapp/orderinfo/getRiceOrderDetails?orderId=' + id)
}

// 拼音获取快递名称
export function kuaidi(params) {
  return https.fetchGet('mallapp/orderinfo/type', params,)
}

// 店铺详情
export const getStoreDetail = function (params) {
  return https.fetchGet('/mallapp/goodsSpuFuNeng/shopInfoGoodsSpu', params)
}

// 收藏店铺
export const collectShop = function (data) {
  return https.fetchPost('/mallapp/usercollect/saveCollect', data)
}

// // 支付新汇付
// export const zhifu = (data) => https.fetchPost('/mallapp/orderinfo/AdaPayExchangeMallPay', data)

// 支付老
// export const zhifu = (data) => https.fetchPost('/mallapp/orderinfo/unifiedOrderExchangeAll', data)

// 支付新汇付
export const zhifu = (data) => https.fetchPost('/mallapp/orderinfo/aliPayExchangeMallPay', data)

// 取消订单
export const cancelOrder = (data) => https.fetchPost('/mallapp/orderinfo/cancelOrder', data)
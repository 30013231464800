<template>
  <div>
    <van-action-sheet v-model="_show" close-on-click-overlay closeable @click-overlay="closePay">
      <div class="content">
        <div style="text-align: center;">
          <span style="font-size: 24px; color: #F52556;">￥</span>
          <span style="font-size: 36px; color: #F52556;">{{ price }}</span>
        </div>

        <div class="paymentType">
          <!-- <div class="paymentType-item" @click="paymentType(1)">
            <img style="width: 18px;" src="../../assets/img/weixin.png" alt="">
            <span class="paymentType-item-type">微信支付</span>
            <span>
              <van-icon v-show="type != 1" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="type == 1" name="checked" color="#FB2B53" size="18" />
            </span>
          </div> -->
          
          <div class="paymentType-item border-btn" @click="paymentType(2)">
            <img style="width: 18px;" src="../../assets/img/alipay-icon.png" alt="">
            <span class="paymentType-item-type">支付宝支付</span>
            <span>
              <van-icon v-show="type != 2" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="type == 2" name="checked" color="#FB2B53" size="18" />
            </span>
          </div>
        </div>

        <div class="footer">
          <van-button round color="#FB2B53" size="large" @click="fukuan">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>
    <!-- <loading :loading="loading" /> -->
  </div>
</template>

<script>
// import Loading from '@/views/flMall/actionSheet/Loading'
import { zhifu } from '@/api/detail'
import { fuli_yundian_pay } from '@/common'
import { Toast } from 'vant'
export default {
  components: {
    // Loading
  },

  data() {
    return {
      type: 2, // 1 微信 2 支付宝
      loading: false,
      timer1: null
    }
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    price: {
      required: true
    },
    data: {
      type: String
    },
    ticketId: {
      type: Object,
      required: true
    }
  },

  computed: {
    _show: {
      get() {
        return this.show
      },
      set() {
        this.$parent.submitShow = false
      }
    }
  },

  methods: {

    //关闭弹出页
    closePay() {
      this.$emit('getSend', false)
    },
    paymentType(type) {
      this.type = type
    },

    fukuan() {
      this.loading = true
      const data = {
        id: this.data,
        type: this.type
      }
      if (this.price == '0') {//价格是0就直接调支付接口生成
        zhifu(Object.assign(data, this.ticketId)).then(res => {
          if (res.data.code === 0) {
            this.closePay();
            this.$parent.paySuccess()
          } else {
            Toast(res.data.message)
          }
        }).catch(err => {
          Toast('支付请求失败', err)
        })
      } else {//如果价格不是0,调支付

        if (this.type == 1) {
          // 微信
          let u = navigator.userAgent;
          let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          let paySession = window.sessionStorage.getItem("third-session");
          if (isIOS) {
            // Toast('返回首页')
            window.webkit.messageHandlers.wechatPay.postMessage(`requesetType=11&type=1&id=${data.id}&session=${paySession}`)
          }
          if (isAndroid) {
            window.android.wxpay(`requesetType=11&type=1&id=${data.id}&session=${paySession}`);
          }
          // openPay(res, '1')
          this.timer1 = setTimeout(() => {
            this.closePay();
          }, 1500)
          // this.closePay()
        } else {

          zhifu(Object.assign(data, this.ticketId)).then(res => {
            if (res.data.code === 0) {
              if (this.type == 2) {
                // 支付宝
                fuli_yundian_pay(res.data, '2')
                // this.closePay()
                this.timer1 = setTimeout(() => {
                  this.closePay();
                }, 1500)
              }
            }
          }).catch(err => {
            Toast('支付请求失败', err)
          })
        }
      }
      // console.log(data);

    },
  },
  destroyed() {
    // clearTimeout(this.timer)
    clearTimeout(this.timer1)
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 40px 15px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .paymentType {
    &-item {
      display: flex;
      align-items: center;
      padding: 12px 0;

      &-type {
        flex: 1;
        font-size: 14px;
        color: #333;
        margin-left: 10px;
      }
    }

    .border-btn {
      border-top: 1px solid #F6F6F6;
      border-bottom: 1px solid #F6F6F6;
    }
  }
}

.footer {
  .van-button--round {
    height: 40px;
  }
}
</style>